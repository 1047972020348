@media screen and (max-width: 767px) {
  .mainTropsensor {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .mainTropsensor {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .mainTropsensor {
    width: 40%;
  }
}
h1 {
  font-size: calc(1.2rem + 1.5vw) !important;
}
h6 {
  font-size: 15px;
}
.batteryContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  float: right;
  margin-right: 15px;
  padding-top: 5px;
}

.batteryShape {
  position: relative;
  width: 30px;
  height: 15px;
  border: 2px solid #7b8d97;
  border-radius: 3px;
}

.batteryShape::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
  width: 2.5px;
  height: 4px;
  background: #7b8d97;
  border-top-right-radius: 0.5px;
  border-bottom-right-radius: 0.5px;
}

.batteryShape::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.level {
  position: absolute;
  top: 0.5px;
  left: 0.3px;
  right: 0.5px;
  bottom: 0.5px;
  border-radius: 2px;
  overflow: hidden;
}

.percentage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background: #455065;
}
