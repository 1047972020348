@media screen and (max-width: 767px) {
  .mainECG {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .mainECG {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .mainECG {
    width: 40%;
  }
}
h6 {
  font-size: 15px;
}

.dygraph-title{
  margin-left: 60px !important;
}


