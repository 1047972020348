@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.components {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  background-color: #fafafa;
}

body {
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  color: #7b8d97;
}
.form-control::placeholder {
  color: #7b8d97;
}
.form-control:-ms-input-placeholder {
  color: #7b8d97;
}

input[type="email"]:focus,
input[type="password"]:focus {
  /* outline: none; */
  box-shadow: none !important;
  border-color: #fb584d;
}

@media screen and (max-width: 767px) {
  .mainTropsensor {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .mainTropsensor {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .mainTropsensor {
    width: 40%;
  }
}
h1 {
  font-size: calc(1.2rem + 1.5vw) !important;
}
h6 {
  font-size: 15px;
}
.batteryContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  float: right;
  margin-right: 15px;
  padding-top: 5px;
}

.batteryShape {
  position: relative;
  width: 30px;
  height: 15px;
  border: 2px solid #7b8d97;
  border-radius: 3px;
}

.batteryShape::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2.5px;
  height: 4px;
  background: #7b8d97;
  border-top-right-radius: 0.5px;
  border-bottom-right-radius: 0.5px;
}

.batteryShape::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.level {
  position: absolute;
  top: 0.5px;
  left: 0.3px;
  right: 0.5px;
  bottom: 0.5px;
  border-radius: 2px;
  overflow: hidden;
}

.percentage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background: #455065;
}

@media screen and (max-width: 767px) {
  .mainECG {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .mainECG {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .mainECG {
    width: 40%;
  }
}
h6 {
  font-size: 15px;
}

.dygraph-title{
  margin-left: 60px !important;
}



