body {
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  color: #7b8d97;
}
.form-control:-moz-placeholder {
  color: #7b8d97;
}
.form-control::-moz-placeholder {
  color: #7b8d97;
}
.form-control::placeholder {
  color: #7b8d97;
}
.form-control:-ms-input-placeholder {
  color: #7b8d97;
}

input[type="email"]:focus,
input[type="password"]:focus {
  /* outline: none; */
  box-shadow: none !important;
  border-color: #fb584d;
}
